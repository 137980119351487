.markdown {
  padding-top: 1rem;
  padding-bottom: 1rem;

  pre {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 10px 15px;
    border: solid 1px #ddd;
    border-radius: 5px;
    background: #fafafa;
    font-size: 1rem;
  }

  :not(pre) {
    code {
      color: #333;
      font-size: 1rem;
      border: solid 1px #ddd;
      border-radius: 5px;
      background: #fafafa;
      padding: 3px 5px;
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  table {
    margin-top: 1rem;
    margin-bottom: 1rem;

    thead {
      th {
        border-bottom: none;
      }
    }

    th {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      background: #fafafa;
    }

    td {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  ul, ol {
    padding-left: 1.5rem;

    li {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: solid 1px #ddd;
    font-size: 2rem;
  }

  h2 {
    margin-top: 1.8rem;
    margin-bottom: 0.9rem;
    padding-bottom: 0.45rem;
    border-bottom: solid 1px #ddd;
    font-size: 1.8rem;
  }

  h3 {
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
  }

  h4 {
    margin-top: 1.4rem;
    margin-bottom: 0.7rem;
    font-size: 1.4rem;
  }

  h5 {
    margin-top: 1.2rem;
    margin-bottom: 0.6rem;
    font-size: 1.2rem;
  }

  h6 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  p.function {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    background: #fafafa;
    padding: 0.5rem 1rem;
    border: solid 1px #ddd;
    border-radius: 6px;
    font-family: Consolas, Menlo, 'Noto Sans Japanese';
  }

  >:first-child {
    margin-top: 0;
  }
}
