@import "lib/bootstrap/scss/bootstrap";
@import "markdown";

html {
  font-size: 14px;
  background-color: #666;
}

body {
  font-family: "Noto Sans JP";
  padding-top: 50px;
  background-color: white;
  //font-size: 0.875rem;
}

table.table {
  td, th {
    vertical-align: middle;
  }
}

table.table-list {
  border-bottom: solid 1px #dee2e6;
  border-left: solid 1px #dee2e6;
  border-right: solid 1px #dee2e6;

  thead {
    background: #f8f9fa;

    tr.heading {
      th {
        text-align: center;
        white-space: nowrap;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    tr.search {
      td {
        border-top: none;
      }
    }
  }

  tbody {
  }

  th, td {
    border-left: dotted 1px #dee2e6;

    &.fit {
      width: 1%;
      white-space: nowrap;
    }
  }
}

.nav-pills .nav-link {
  border-radius: 0;
}

.breadcrumbs {
  padding: 10px 0;
  margin: 0;

  li {
    display:inline;
    list-style: none;
  }

  li:after {
    content: '>';
    padding: 0 3px;
  }
}

.btn {
  white-space: nowrap;
}

.sidebar {
  width: 250px;
  padding-left: 15px;
  padding-right: 15px;
  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: block;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #ffffff;
  border-right: 1px solid #eee;
}

.main {
  padding-left: 265px;
}

#content {
}

footer {
  padding-top: 30px;
  border-top: solid 1px #333;
  background-color: #666;
  color: white;

  ul {
    padding-left: 0;

    li {
      list-style: none;
      line-height: 2rem;

      a {
        color: white;

        &:hover {
          color: white;
        }
      }
    }
  }
}
